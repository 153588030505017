export const convertToTwoDig = (numb) => (numb < 10 ? `0${numb}` : numb)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

export const makeFirstCharCapital = (word) => {
  if (!word) return ""
  const newWord = [...word]
  newWord[0] = newWord[0].toUpperCase()
  return newWord.join("")
}

export const makeStringInitialsCapital = (str) => {
  return str
    .split(" ")
    .map((w) => makeFirstCharCapital(w))
    .join(" ")
}

export const limitStringChar = (str, len) => {
  if (str.length > len) {
    const nS = [...str].slice(0, len).join("")
    return `${nS}...`
  }
  return str
}

export const limitStringWord = (str, len) => {
  const words = str.split(" ")
  if (words.length > len) {
    const nS = words.slice(0, len).join(" ")
    return `${nS}...`
  }
  return str
}

export const toCamelCase = (word) => {
  if (!word) return ""
  const newWord = word.slice(0, 1).toUpperCase() + word.slice(1)
  return newWord
}

export const toCapitalize = (s) => {
  return s && s[0].toUpperCase() + s.toLowerCase().slice(1)
}
