import { ActionCodeOperation } from "@firebase/auth"
import { setEndTimeInDate, setZeroTimeInDate } from "../../../../../utility/helperFunctions/dateFormat"

const getDefaultFilters = () => {
  const d = Date.now()
  // const start = Date.now() - 86400000
  // return [{ start: 1546318800000, end: setEndTimeInDate(d) }]
  return [{ start: setZeroTimeInDate(d) - 86400000, end: setEndTimeInDate(d) }]
}
const DEFAULT_ORDER_STATS = {
  salesTotal: 0,
  salesSubTotal: 0,
  uniques: 0,
}

// **  Initial State
const initialState = {
  ordersData: [],
  itemStats: [],
  refreshDataRequired: true,
  hasComponentMountedOnce: false,
  isLoading: true,
  itemLoading: true,
  statsLoading: false,
  currentPage: 0,
  totalOrders: 0, //total records in currently selected filters
  actualTotal: 0,
  allTimeTotal: 0, //all time total records irrespective of filters
  // ordersPerPage: 1,
  ordersPerPage: 25,
  selectedFilters: getDefaultFilters(),
  orderStats: DEFAULT_ORDER_STATS,
}

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_ORDERS": {
      const total = action.data.total > 10000 ? 10000 : action.data.total //due to algolia limit
      return { ...state, ordersData: action.data.orders, totalOrders: total, actualTotal: action.data.total }
    }
    case "UPDATE_ORDER": {
      const newOrders = state.ordersData.map((d) => {
        if ((d.objectID && d.objectID === action.data.objectID) || (d.id && d.id === action.data.id)) {
          return action.data
        }
        return d
      })
      return { ...state, ordersData: newOrders }
    }
    case "DELETE_ORDER": {
      const newOrdersData = { ...state.ordersData }
      delete newOrdersData[action.data]
      return { ...state, ordersData: newOrdersData }
    }
    case "SET_ORDER_LOADING": {
      if (action.data) {
        return {
          ...state,
          isLoading: action.data,
          ordersData: [],
          // allTimeTotal: 0,
          // orderStats: DEFAULT_ORDER_STATS
        }
      }
      return { ...state, isLoading: action.data }
    }
    case "SET_ITEMS_LOADING": {
      return { ...state, statsLoading: action.data }
    }
    case "SET_STATS_LOADING": {
      return { ...state, itemLoading: action.data }
    }
    case "SET_ORDER_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }
    case "SET_ORDER_CURRENT_PAGE": {
      return { ...state, currentPage: action.data }
    }
    case "SET_ORDER_FITLERS": {
      return { ...state, selectedFilters: action.data }
    }
    case "SET_ORDER_PER_PAGE": {
      return { ...state, ordersPerPage: action.data }
    }
    case "SET_ALL_TIME_ORDERS_TOTAL": {
      return { ...state, allTimeTotal: action.data }
    }
    case "SET_ORDER_STATS": {
      return { ...state, orderStats: action.data }
    }
    case "VIEW_ITEM_STATS": {
      return { ...state, itemStats: action.data }
    }
    case "SET_ORDER_DATA_REFRESH": {
      return { ...state, refreshDataRequired: action.data }
    }
    case "RESET_ORDER_FILTERS_AND_DATA": {
      return {
        ...state,
        ordersData: [],
        currentPage: 0,
        totalOrders: 0,
        actualTotal: 0,
        ordersPerPage: 50,
        stats: {},
        allTimeTotal: 0,
        refreshDataRequired: true,
        statsLoading: false,
        // selectedFilters: getDefaultFilters()
        selectedFilters: getDefaultFilters(),
      }
    }

    default:
      return state
  }
}

export default ordersReducer
