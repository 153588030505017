// **  Initial State
const initialState = {
  balanceData: {},
  isLoading: true
}

const balanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_BALANCE": {
      const newBalanceData = { ...state.balanceData }
      action.data.forEach((r) => {
        newBalanceData[r.id] = r.value
        newBalanceData[r.id].reward_amount = newBalanceData[r.id].reward_amount / 100
        newBalanceData[r.id].threshold_amount = newBalanceData[r.id].threshold_amount / 100
      })

      return { ...state, balanceData: newBalanceData }
    }
    case "SET_BALANCE_LOADING": {
      return { ...state, isLoading: action.data }
    }
    default:
      return state
  }
}

export default balanceReducer
