// **  Initial State
const initialState = {
  stock: [],
  hasComponentMountedOnce: false,
  isLoading: true,
}

const prodStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_PROD_STOCK": {
      return { ...state, stock: action.data, isLoading: false }
    }
    case "UPDATE_PROD_STOCK": {
      const newStock = state.stock.map((d) => {
        if (d.id === action.data.id) {
          return action.data
        }
        return d
      })
      return { ...state, stock: newStock }
    }
    case "DELETE_PROD_STOCK": {
      const newStock = { ...state.stock }
      delete newStock[action.data]
      return { ...state, stock: newStock }
    }
    case "SET_PROD_STOCK_LOADING": {
      if (action.data) {
        return {
          ...state,
          isLoading: action.data,
          stock: [],
        }
      }
      return { ...state, isLoading: action.data }
    }

    case "SET_PROD_STOCK_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }

    case "RESET_PROD_STOCK_FILTERS_AND_DATA": {
      return { ...initialState, hasComponentMountedOnce: state.hasComponentMountedOnce }
    }

    default:
      return state
  }
}

export default prodStockReducer
