// ** Logo
import logo from "@src/assets/images/logo/logo.png"
import { useSelector } from "react-redux"

const SpinnerComponent = () => {
  const skin = useSelector((state) => state.layout.skin)
  return (
    <div className="fallback-spinner vh-100" style={{ backgroundColor: skin === "dark" ? "#161d31" : "" }}>
      <img className="fallback-logo" src={logo} alt="logo" />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
