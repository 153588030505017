// **  Initial State
const initialState = {
  rewardsData: {},
  hasComponentMountedOnce: false,
  isLoading: true
}

const rewardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_REWARDS": {
      const newRewardData = { ...state.rewardsData }
      action.data.forEach((r) => {
        newRewardData[r.id] = r.value
        newRewardData[r.id].reward_amount = newRewardData[r.id].reward_amount / 100
        newRewardData[r.id].threshold_amount = newRewardData[r.id].threshold_amount / 100
      })

      return { ...state, rewardsData: newRewardData }
    }
    case "UPDATE_REWARD": {
      const newRewardData = { ...state.rewardsData }
      newRewardData[action.data.id] = action.data.value
      newRewardData[action.data.id].reward_amount = newRewardData[action.data.id].reward_amount / 100
      newRewardData[action.data.id].threshold_amount = newRewardData[action.data.id].threshold_amount / 100
      return { ...state, rewardsData: newRewardData }
    }
    case "DELETE_REWARD": {
      const newRewardData = { ...state.rewardsData }
      delete newRewardData[action.data]
      return { ...state, rewardsData: newRewardData }
    }
    case "SET_REWARD_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "SET_REWARD_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }
    default:
      return state
  }
}

export default rewardReducer
