// **  Initial State
const initialState = {
  eventsData: {},
  eventsStatsData: {},
  hasComponentMountedOnce: false,
  isLoading: true,
  isStatsLoading: true,
}

const mattoEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_MATTO_EVENT": {
      const newData = { ...state.eventsData }
      action.data.forEach((e) => {
        newData[e.id] = e.value
      })
      return { ...state, eventsData: newData }
    }
    case "ADD_NEW_MATTO_EVENT_STATS": {
      const newData = { ...state.eventsStatsData }
      action.data.forEach((e) => {
        newData[e.id] = e.value
      })
      return { ...state, eventsStatsData: newData }
    }
    case "UPDATE_MATTO_EVENT": {
      const newData = { ...state.eventsData }
      newData[action.data.id] = action.data.value
      return { ...state, eventsData: newData }
    }
    case "UPDATE_MATTO_EVENT_STATS": {
      const newData = { ...state.eventsStatsData }
      newData[action.data.id] = action.data.value
      return { ...state, eventsStatsData: newData }
    }
    case "DELETE_MATTO_EVENT": {
      const newData = { ...state.eventsData }
      delete newData[action.data]
      return { ...state, eventsData: newData }
    }
    case "DELETE_MATTO_EVENT_STATS": {
      const newData = { ...state.eventsStatsData }
      delete newData[action.data]
      return { ...state, eventsStatsData: newData }
    }
    case "SET_MATTO_EVENT_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "SET_MATTO_EVENT_STATS_LOADING": {
      return { ...state, isStatsLoading: action.data }
    }
    case "SET_MATTO_EVENT_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }

    default:
      return state
  }
}

export default mattoEventsReducer
