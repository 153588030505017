// **  Initial State
const initialState = {
  vouchersData: {},
  vouchersStatsData: {},
  hasComponentMountedOnce: false,
  isLoading: true,
  isStatsLoading: true,
}

const vouchersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_VOUCHER": {
      const newData = { ...state.vouchersData }
      action.data.forEach((e) => {
        newData[e.id] = e.value
      })
      return { ...state, vouchersData: newData }
    }
    case "ADD_NEW_VOUCHER_STATS": {
      const newData = { ...state.vouchersStatsData }
      action.data.forEach((e) => {
        newData[e.id] = e.value
      })
      return { ...state, vouchersStatsData: newData }
    }
    case "UPDATE_VOUCHER": {
      const newData = { ...state.vouchersData }
      newData[action.data.id] = action.data.value
      return { ...state, vouchersData: newData }
    }
    case "UPDATE_VOUCHER_STATS": {
      const newData = { ...state.vouchersStatsData }
      newData[action.data.id] = action.data.value
      return { ...state, vouchersStatsData: newData }
    }
    case "DELETE_VOUCHER": {
      const newData = { ...state.vouchersData }
      delete newData[action.data]
      return { ...state, vouchersData: newData }
    }
    case "DELETE_VOUCHER_STATS": {
      const newData = { ...state.vouchersStatsData }
      delete newData[action.data]
      return { ...state, vouchersStatsData: newData }
    }
    case "SET_VOUCHER_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "SET_VOUCHER_STATS_LOADING": {
      return { ...state, isStatsLoading: action.data }
    }
    case "SET_VOUCHER_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }
    default:
      return state
  }
}

export default vouchersReducer
