import { ActionCodeOperation } from "@firebase/auth"

// **  Initial State
const initialState = {
  promData: {},
  hasComponentMountedOnce: false,
  isLoading: true
}

const promReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_PROMOTIONS": {
      const newPromData = { ...state.promData }
      action.data.forEach((p) => {
        newPromData[p.id] = p.value
      })
      return { ...state, promData: newPromData }
    }
    case "UPDATE_PROMOTION": {
      const newPromData = { ...state.promData }
      newPromData[action.data.id] = action.data.value
      return { ...state, promData: newPromData }
    }
    case "DELETE_PROMOTION": {
      const newPromData = { ...state.promData }
      delete newPromData[action.data.id]
      return { ...state, promData: newPromData }
    }
    case "SET_PROMOTION_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "SET_PROMOTION_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }

    default:
      return state
  }
}

export default promReducer
