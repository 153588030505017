// Disable missing translation message as translations will be added later.
// We can add a toggle for this later when we have most translations.

// eslint-disable-next-line
const consoleError = console.error.bind(console)
const consoleLog = console.log.bind(console)
const consoleDebug = console.debug.bind(console)
// eslint-disable-next-line
console.error = (message, ...args) => {
  // if (process.env.NODE_ENV === "production") return
  if (typeof message === "string" && message.startsWith("[React Intl] Missing message:")) return
  if (typeof message === "string" && message.startsWith("[React Intl] Cannot format message:")) return

  consoleError(message, ...args)
}

// console.log = (message, ...args) => {
//   if (process.env.NODE_ENV === "production") return
//   consoleLog(message, ...args)
// }

// console.debug = (message, ...args) => {
//   if (process.env.NODE_ENV === "production") return
//   consoleDebug(message, ...args)
// }
