import { ActionCodeOperation } from "@firebase/auth"
import { setEndTimeInDate } from "../../../../../utility/helperFunctions/dateFormat"

const getDefaultFilters = () => {
  const d = Date.now()
  return [{ start: 1559433600000, end: setEndTimeInDate(d) }]
}

// **  Initial State
const initialState = {
  customersData: [],
  hasComponentMountedOnce: false,
  isLoading: false,
  currentPage: 0,
  totalCustomers: 0, //total records in currently selected filters
  allTimeTotal: 0, //all time total records irrespective of filters
  customersPerPage: 10,
  selectedFilters: getDefaultFilters(),
  sortObject: { rankingName: "", ranking: [] },
  mergeAccountDetails: { isMergeActive: false, destinationId: "", mergeList: [], errorAccountIds: {} },
}

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_CUSTOMERS": {
      const total = action.data.total > 10000 ? 10000 : action.data.total //due to algolia limit
      return { ...state, customersData: action.data.customers, totalCustomers: total }
    }
    case "UPDATE_CUSTOMER": {
      const newCustomers = state.customersData.map((d) => {
        return d.objectID === action.data.objectID ? action.data : d
      })
      return { ...state, customersData: newCustomers }
    }
    case "DELETE_CUSTOMER": {
      const newCustomers = { ...state.customersData }
      delete newCustomers[action.data]
      return { ...state, customersData: newCustomers }
    }
    case "SET_CUSTOMER_LOADING": {
      if (action.data) {
        return {
          ...state,
          isLoading: action.data,
          customersData: [],
        }
      }
      return { ...state, isLoading: action.data }
    }
    case "SET_CUSTOMER_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }
    case "SET_CUSTOMER_CURRENT_PAGE": {
      return { ...state, currentPage: action.data }
    }
    case "SET_CUSTOMER_FITLERS": {
      return { ...state, selectedFilters: action.data }
    }
    case "SET_CUSTOMER_PER_PAGE": {
      return { ...state, customersPerPage: action.data }
    }
    case "SET_ALL_TIME_CUSTOMERS_TOTAL": {
      return { ...state, allTimeTotal: action.data }
    }
    case "SET_CUSTOMER_SORTING": {
      return { ...state, sortObject: action.data }
    }
    case "SET_CUSTOMER_MERGE_ACCOUNT_DETAILS": {
      return { ...state, mergeAccountDetails: { ...state.mergeAccountDetails, ...action.data } }
    }
    case "SET_CUSTOMER_MERGE_DESTINATION_ACCOUNT_IDS": {
      return { ...state, mergeAccountDetails: { ...state.mergeAccountDetails, destinationId: action.data } }
    }

    case "SET_CUSTOMER_MERGE_ERROR_ACCOUNT_IDS": {
      const mergeAccountDetails = { ...state.mergeAccountDetails, errorAccountIds: action.data }
      localStorage.setItem("MergeAccounts", JSON.stringify(mergeAccountDetails))
      return { ...state, mergeAccountDetails}
    }

    case "SET_CUSTOMER_MERGE_LIST": {
      const mergeList = state.mergeAccountDetails.mergeList || []
      const mergeCustomer = action.data

      let updatedMergeList = []
      let destinationId = state.mergeAccountDetails.destinationId

      const isAlreadyMerged = mergeList.some((customer) => customer.id === mergeCustomer.id)

      if (isAlreadyMerged) {
        updatedMergeList = mergeList.filter((customer) => customer.id !== mergeCustomer.id)
      } else {
        updatedMergeList = [...mergeList, mergeCustomer]
      }

       // Sort the updatedMergeList by last_visit in descending order
      updatedMergeList.sort((a, b) => b.num_visits - a.num_visits)
      if (!isAlreadyMerged) {
        destinationId = updatedMergeList?.[0]?.id
      } else {
        const isDestinationExist = updatedMergeList.some((customer) => customer.id === destinationId)
        if (!isDestinationExist) {
          destinationId = updatedMergeList?.[0]?.id || ""
        }
      }

      const mergeAccountDetails = {
        ...state.mergeAccountDetails,
        mergeList: updatedMergeList,
        destinationId,
      }
      if (updatedMergeList.length === 0) {
        mergeAccountDetails.errorAccountIds = {}
      }
      localStorage.setItem("MergeAccounts", JSON.stringify(mergeAccountDetails))
      return {
        ...state,
        mergeAccountDetails,
      }
    }
    case "SET_CUSTOMER_MERGE_LIST_MOUNTED": {
      const mergeListStringify = localStorage.getItem("MergeAccounts")
      if (!mergeListStringify) return { ...state}
      const mergeAccountDetails = JSON.parse(mergeListStringify)

      return {
        ...state,
        mergeAccountDetails,
      }
    }
    case "RESET_MERGE_ACCOUNT_DETAILS": {
      localStorage.removeItem("MergeAccounts")
      return { ...state, mergeAccountDetails: { isMergeActive: false, destinationId: "", mergeList: [], errorAccountIds: {} } }
    }

    case "RESET_CUSTOMER_FILTERS_AND_DATA": {
      return {
        ...state,
        customersData: [],
        currentPage: 0,
        totalCustomers: 0,
        customersPerPage: 50,
        stats: {},
        allTimeTotal: 0,
        selectedFilters: getDefaultFilters(),
        sortObject: { rankingName: "", ranking: [] },
        mergeAccountDetails: { isMergeActive: false, destinationId: "", mergeList: [], errorAccountIds: {} },
      }
    }

    default:
      return state
  }
}

export default customersReducer
