const initialState = {
  logs: [],
  isLoading: false,
  filters: {
    type: "",
    subType: "",
    doneBy: "",
    action: "",
    platform: "",
    perPage: { label: 50, value: 50 },
    startDate: "",
    endDate: ""
  }
}

const activityLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_EVENTLOGS": {
      return { ...state, logs: action.data, isLoading: false }
    }
    case "SET_EVENTLOGS_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "UPDATE_EVENTLOGS_FITLERS": {
      const newFilters = { ...state.filters }
      newFilters[action.data.key] = action.data.value
      return { ...state, filters: newFilters }
    }
    case "RESET_EVENTLOGS_FILTERS": {
      return {
        ...state,
        isLoading: false,
        filters: {
          type: "",
          subType: "",
          doneBy: "",
          action: "",
          platform: "",
          perPage: { label: 50, value: 50 },
          startDate: "",
          endDate: ""
        }
        // logs: []
      }
    }
    default:
      return state
  }
}

export default activityLogsReducer
