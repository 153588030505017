// **  Initial State
const initialState = {
  userData: {},
  isLoggingIn: true,
  isLoggedIn: false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        userData: action.data
      }
    case "USER_LOGGING_IN":
      return {
        ...state,
        isLoggingIn: true
      }

    case "LOGOUT":
      // const obj = { ...action }
      // delete obj.type
      return { ...state, userData: {}, isLoggingIn: false, isLoggedIn: false }
    default:
      return state
  }
}

export default authReducer
