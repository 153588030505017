// **  Initial State
const initialState = {
  categoryData: {},
  isLoading: true,
  hasComponentMountedOnce: false
}

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_CATEGORIES": {
      const newCatData = { ...state.categoryData }
      action.data.forEach((c) => {
        newCatData[c.id] = c.value
      })
      return { ...state, categoryData: newCatData }
    }
    case "UPDATE_CATEGORY": {
      const newCatData = { ...state.categoryData }
      newCatData[action.data.id] = action.data.value
      return { ...state, categoryData: newCatData }
    }

    case "DELETE_CATEGORY": {
      const newCatData = { ...state.categoryData }
      delete newCatData[action.data]
      return { ...state, categoryData: newCatData }
    }

    case "SET_CATEGORY_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "SET_CATEGORY_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }
    default:
      return state
  }
}

export default categoryReducer
