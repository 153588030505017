// **  Initial State
const initialState = {
  modifiers: [],
  hasComponentMountedOnce: false,
  isLoading: true
}

const modifiersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MODIFIERS':
      return {
        ...state,
        modifiers: action.data
      }
    case "SET_MODIFIER_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "ADD_NEW_MODIFIERS": {
      const newModifiers = { ...state.modifiers }
      action.data.forEach((m) => {
        newModifiers[m.id] = m.value
      })
      return { ...state, modifiers: newModifiers }
    }
    case "UPDATE_MODIFIER": {
      const newModifiers = { ...state.modifiers }
      newModifiers[action.data.id] = action.data.value
      return { ...state, modifiers: newModifiers }
    }
    case "DELETE_MODIFIER": {
      const newModifiers = { ...state.modifiers }
      delete newModifiers[action.data]
      return { ...state, modifiers: newModifiers }
    }
    case "SET_MODIFIER_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }
    default:
      return state
  }
}

export default modifiersReducer
