export const defaultFilters = {
  status: "",
  perPage: { label: 50, value: 50 },
  startDate: "",
  // endDate: setEndTimeInDate(Date.now())
  endDate: "",
}

const initialState = {
  syncRequests: [],
  isLoading: false,
  filters: defaultFilters,
  hasComponentMountedOnce: false,
}

const syncAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_SYNCACCOUNT_REQUEST": {
      return { ...state, syncRequests: action.data, isLoading: false }
    }

    case "SET_SYNCACCOUNT_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "UPDATE_SYNCACCOUNT_FITLERS": {
      const newFilters = { ...state.filters }
      newFilters[action.data.key] = action.data.value
      return { ...state, filters: newFilters }
    }
    case "RESET_SYNCACCOUNT_FILTERS": {
      return {
        ...state,
        isLoading: false,
        filters: defaultFilters,
        // logs: []
      }
    }
    case "SET_SYNCACCOUNT_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }

    default:
      return state
  }
}

export default syncAccountsReducer
