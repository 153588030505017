const initialState = {
  managersData: [],
  managersAndAdminData: [],
  hasComponentMountedOnce: false,
  isLoading: true,
  currentPage: 0,
  totalManagers: 0,
  managersPerPage: 50,
  selectedFilters: [],
}

const managersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_MANAGERS": {
      return { ...state, managersData: action.data.managers, totalManagers: action.data.managers.length }
    }
    case "APPEND_NEW_MANAGER": {
      return {
        ...state,
        managersData: state.managersData.concat(action.data.manager),
        totalManagers: state.totalManagers + 1,
      }
    }
    case "DELETE_MANAGER": {
      return {
        ...state,
        managersData: state.managersData.filter((item) => item.id !== action.data.managerId),
        totalManagers: state.totalManagers - 1,
      }
    }
    case "UPDATE_MANAGER": {
      const managersData = state.managersData.map((manager) => {
        if (manager.id === action.data.id) {
          return { ...manager, ...action.data }
        } else {
          return manager
        }
      })
      return { ...state, managersData }
    }
    case "ADD_NEW_MANAGERS_AND_ADMINS": {
      console.log("action:", action.data.managersAndAdmins)
      return { ...state, managersAndAdminData: action.data.managersAndAdmins }
    }
    case "SET_MANAGER_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "SET_MANAGER_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }
    case "SET_MANAGER_CURRENT_PAGE": {
      return { ...state, currentPage: action.data }
    }
    case "SET_MANAGER_FITLERS": {
      return { ...state, selectedFilters: action.data }
    }
    case "SET_MANAGER_PER_PAGE": {
      return { ...state, managersPerPage: action.data }
    }
    case "RESET_MANAGER_FILTERS_AND_DATA": {
      return {
        ...state,
        managersData: [],
        currentPage: 0,
        totalManagers: 0,
        managersPerPage: 50,
        selectedFilters: [],
      }
    }
    default:
      return state
  }
}

export default managersReducer
