import { setZeroTimeInDate, setEndTimeInDate } from "../../../../../utility/helperFunctions/dateFormat"

const getDefaultFilters = () => {
  const d = Date.now()
  // eslint-disable-next-line no-mixed-operators
  return { fromTime: setZeroTimeInDate(d) - 7 * 24 * 60 * 60 * 1000, toTime: setEndTimeInDate(d) }
}

// **  Initial State
const initialState = {
  ordersTransferData: {},
  hasComponentMountedOnce: false,
  isLoading: true,
  currentPage: 0,
  ordersPerPage: { label: "25", value: 25 },
  selectedFilters: getDefaultFilters(),
}

/* eslint-disable */
const orderTransferReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_ORDERS_TRANSFER_REQ": {
      const newData = { ...state.ordersTransferData }
      action.data.forEach((c) => {
        newData[c.id] = c.value
      })
      return { ...state, ordersTransferData: newData }
    }

    case "UPDATE_ORDER_TRANSFER_REQ": {
      const newData = { ...state.ordersTransferData }
      newData[action.data.id] = action.data.value
      return { ...state, ordersTransferData: newData }
    }

    case "DELETE_ORDER_TRANSFER_REQ": {
      const newData = { ...state.ordersTransferData }
      delete newData[action.data]
      return { ...state, ordersTransferData: newData }
    }

    case "SET_ORDER_TRANSFER_REQ_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "SET_ORDER_TRANSFER_REQ_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }

    case "SET_ORDER_TRANSFER_REQ_CURRENT_PAGE": {
      return { ...state, currentPage: action.data }
    }
    case "SET_ORDER_TRANSFER_REQ_FITLERS": {
      return { ...state, selectedFilters: action.data }
    }
    case "SET_ORDER_TRANSFER_REQ_PER_PAGE": {
      return { ...state, ordersPerPage: action.data }
    }

    case "RESET_ORDER_TRANSFER_REQ_FILTERS": {
      return {
        ...state,
        currentPage: 0,
        ordersPerPage: { label: "25", value: 25 },
        // selectedFilters: getDefaultFilters()
        selectedFilters: getDefaultFilters(),
      }
    }

    default:
      return state
  }
}

export default orderTransferReducer
