// **  Initial State
const initialState = {
  couponsData: {},
  couponsStatsData: {},
  isLoading: true,
  hasComponentMountedOnce: false,
  isStatsLoading: true,
}

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_COUPONS": {
      const newCpnData = { ...state.couponsData }
      action.data.forEach((c) => {
        newCpnData[c.id] = c.value
      })
      return { ...state, couponsData: newCpnData }
    }
    case "ADD_NEW_COUPONS_STATS": {
      const newData = { ...state.couponsStatsData }
      action.data.forEach((e) => {
        newData[e.id] = e.value
      })
      return { ...state, couponsStatsData: newData }
    }
    case "UPDATE_COUPON": {
      const newCpnData = { ...state.couponsData }
      newCpnData[action.data.id] = action.data.value
      return { ...state, couponsData: newCpnData }
    }
    case "UPDATE_COUPON_STATS": {
      const newData = { ...state.couponsStatsData }
      newData[action.data.id] = action.data.value
      return { ...state, couponsStatsData: newData }
    }
    case "DELETE_COUPON": {
      const newCpnData = { ...state.couponsData }
      delete newCpnData[action.data]
      return { ...state, couponsData: newCpnData }
    }
    case "DELETE_COUPON_STATS": {
      const newData = { ...state.couponsStatsData }
      delete newData[action.data]
      return { ...state, couponsStatsData: newData }
    }
    case "SET_COUPON_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "SET_COUPON_STATS_LOADING": {
      return { ...state, isStatsLoading: action.data }
    }
    case "SET_COUPON_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }
    default:
      return state
  }
}

export default categoryReducer
