import { ActionCodeOperation } from "@firebase/auth"

// **  Initial State
const initialState = {
  locationsData: {},
  hasComponentMountedOnce: false,
  isLoading: true,
}

const locationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_LOCATIONS": {
      const newLocationsData = { ...state.locationsData }
      action.data.forEach((l) => {
        if (l.value?.status) {
          newLocationsData[l.id] = l.value
        }
      })
      return { ...state, locationsData: newLocationsData }
    }
    case "UPDATE_LOCATION": {
      const newLocationsData = { ...state.locationsData }
      newLocationsData[action.data.id] = action.data.value
      return { ...state, locationsData: newLocationsData }
    }
    case "DELETE_LOCATION": {
      const newLocationsData = { ...state.locationsData }
      delete newLocationsData[action.data]
      return { ...state, locationsData: newLocationsData }
    }
    case "SET_LOCATION_LOADING": {
      // console.log("location called and set to", action.data)
      // if (action.data) return { ...state, isLoading: action.data, locationsData: {} }
      return { ...state, isLoading: action.data }
    }
    case "SET_LOCATION_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }

    default:
      return state
  }
}

export default locationsReducer
