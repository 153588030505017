// **  Initial State
const initialState = {
  giftCardsData: {},
  isLoading: true,
  hasComponentMountedOnce: false
}

const giftCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_GIFTCARDS": {
      const newCardData = { ...state.giftCardsData }
      action.data.forEach((c) => {
        newCardData[c.id] = c.value
      })
      return { ...state, giftCardsData: newCardData }
    }
    case "UPDATE_GIFTCARD": {
      const newCardData = { ...state.giftCardsData }
      newCardData[action.data.id] = action.data.value
      return { ...state, giftCardsData: newCardData }
    }

    case "DELETE_GIFTCARD": {
      const newCardData = { ...state.giftCardsData }
      delete newCardData[action.data]
      return { ...state, giftCardsData: newCardData }
    }

    case "SET_GIFTCARD_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "SET_GIFTCARD_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }
    default:
      return state
  }
}

export default giftCardsReducer
