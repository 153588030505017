// **  Initial State
const initialState = {
  locationWiseGraph: { data: [], loaded: false },
  itemWiseGraph: { data: [], loaded: false },
  totalOrdersGraph: { data: { d1: [], d2: [] }, loaded: false },
  revenueGraph: { data: [], loaded: false },
  customerTurnAroundGraph: { data: [], loaded: false }
}

const mixpanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOCATION_WISE_GRAPH":
      return {
        ...state,
        locationWiseGraph: action.data
      }
    case "ITEM_WISE_GRAPH":
      return {
        ...state,
        itemWiseGraph: action.data
      }
    case "TOTAL_ORDERS_GRAPH":
      return {
        ...state,
        totalOrdersGraph: action.data
      }

    case "REVENUE_GRAPH":
      return { ...state, revenueGraph: action.data }

    case "CUSTOMER_TURNAROUND_GRAPH":
      return {
        ...state,
        customerTurnAroundGraph: action.data
      }
    default:
      return state
  }
}

export default mixpanelReducer
