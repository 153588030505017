// **  Initial State
const initialState = {
  products: [],
  hasComponentMountedOnce: false,
  isLoading: true
}

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PRODUCTS":
      return {
        ...state,
        products: action.data
      }
    case "GET_SELECTED_PRODUCT":
      return {
        ...state,
        selectedProduct: action.data
      }
    case "ADD_NEW_PRODUCTS": {
      const newProducts = { ...state.products }
      action.data.forEach((p) => {
        newProducts[p.id] = p.value
      })
      return { ...state, products: newProducts }
    }
    case "UPDATE_PRODUCT": {
      const newProducts = { ...state.products }
      newProducts[action.data.id] = action.data.value
      return { ...state, products: newProducts }
    }
    case "DELETE_PRODUCT": {
      const newProducts = { ...state.products }
      delete newProducts[action.data]
      return { ...state, products: newProducts }
    }
    case "SET_PRODUCT_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }
    case "SET_PRODUCT_LOADING": {
      return { ...state, isLoading: action.data }
    }
    default:
      return state
  }
}

export default productsReducer
