// **  Initial State
const initialState = {
  notificationsData: {},
  hasComponentMountedOnce: false,
  isLoading: true
}

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_NOTIFICATION": {
      const newData = { ...state.notificationsData }
      action.data.forEach((e) => {
        newData[e.id] = e.value
      })
      return { ...state, notificationsData: newData }
    }
    case "UPDATE_NOTIFICATION": {
      const newData = { ...state.notificationsData }
      newData[action.data.id] = action.data.value
      return { ...state, notificationsData: newData }
    }
    case "DELETE_NOTIFICATION": {
      const newData = { ...state.notificationsData }
      delete newData[action.data]
      return { ...state, notificationsData: newData }
    }
    case "SET_NOTIFICATION_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "SET_NOTIFICATION_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }

    default:
      return state
  }
}

export default notificationsReducer
